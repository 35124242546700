import React from 'react';
import { Mail, Phone, MapPin, CheckCircle2, Star, ShoppingBag } from 'lucide-react';

const MarketingPage = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0">
              <span className="text-2xl font-bold text-fuchsia-600">Vindr</span>
            </div>
            <div className="hidden md:block">
              <div className="flex items-center space-x-8">
                <a href="#features" className="text-gray-700 hover:text-fuchsia-600">Why Vindr</a>
                <a href="#products" className="text-gray-700 hover:text-fuchsia-600">Our Shoes</a>
                <a href="#contact" className="text-gray-700 hover:text-fuchsia-600">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-fuchsia-50 to-pink-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
                Start Your Running Journey
                <span className="block text-fuchsia-600">With Perfect Comfort</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl">
                High-performance running shoes designed for beginners,
                featuring premium technology at an affordable price point.
              </p>
              <div className="mt-5 sm:mt-8">
                <a
                  href="#products"
                  className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-fuchsia-600 hover:bg-fuchsia-700 md:py-4 md:text-lg md:px-10"
                >
                  Shop Now
                  <ShoppingBag className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
            <div className="relative">
              <img 
                src="/api/placeholder/600/400"
                alt="Runner in action"
                className="rounded-lg shadow-xl w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Why Choose Vindr
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              We make premium running technology accessible to everyone.
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {[
              {
                title: 'Advanced Cushioning',
                description: 'Premium foam technology provides optimal comfort and support for new runners.'
              },
              {
                title: 'Affordable Premium',
                description: 'High-end features at prices 30-40% lower than major brands.'
              },
              {
                title: 'Beginner Friendly',
                description: 'Designed specifically for new runners with focus on comfort and stability.'
              }
            ].map((feature, index) => (
              <div key={index} className="p-6 bg-fuchsia-50 rounded-lg">
                <CheckCircle2 className="h-6 w-6 text-fuchsia-600" />
                <h3 className="mt-4 text-lg font-medium text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-base text-gray-500">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Products Section */}
      <div id="products" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Collection</h2>
            <p className="mt-4 text-lg text-gray-500">
              Premium running shoes designed for your comfort
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {[
              {
                name: 'Vindr Stride',
                price: '$89.99',
                description: 'Perfect for daily training with extra cushioning',
                rating: 4.8
              },
              {
                name: 'Vindr Pace',
                price: '$99.99',
                description: 'Lightweight design for speed training',
                rating: 4.9
              },
              {
                name: 'Vindr Comfort',
                price: '$79.99',
                description: 'Maximum comfort for long distance runs',
                rating: 4.7
              }
            ].map((product, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                <div className="p-4">
                  <img 
                    src="/api/placeholder/400/300" 
                    alt={`${product.name} running shoe`}
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <h3 className="text-lg font-medium text-gray-900">{product.name}</h3>
                  <div className="flex items-center mt-1">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className={`h-4 w-4 ${i < Math.floor(product.rating) ? 'text-yellow-400' : 'text-gray-200'}`} fill="currentColor" />
                    ))}
                    <span className="ml-2 text-sm text-gray-500">{product.rating}</span>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">{product.description}</p>
                  <div className="mt-4 flex items-center justify-between">
                    <span className="text-lg font-bold text-gray-900">{product.price}</span>
                    <button className="px-4 py-2 bg-fuchsia-600 text-white rounded-md hover:bg-fuchsia-700">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-fuchsia-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">What Runners Say</h2>
            <div className="mt-12 grid grid-cols-1 gap-8 md:grid-cols-2">
              {[
                {
                  quote: "Perfect for my first running shoes. Great comfort and amazing price!",
                  author: "Sarah M.",
                  title: "New Runner"
                },
                {
                  quote: "Can't believe the quality at this price point. Highly recommend for beginners.",
                  author: "Mike R.",
                  title: "Casual Runner"
                }
              ].map((testimonial, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                  <div className="flex items-start space-x-4">
                    <img 
                      src="/api/placeholder/64/64" 
                      alt={testimonial.author}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <div>
                      <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                      <div className="mt-4">
                        <h4 className="font-medium text-gray-900">{testimonial.author}</h4>
                        <p className="text-gray-500">{testimonial.title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div id="contact" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Contact Us</h2>
            <p className="mt-4 text-lg text-gray-500">
              Have questions? We're here to help you start your running journey.
            </p>
          </div>
          
          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <form className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fuchsia-500 focus:ring-fuchsia-500"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fuchsia-500 focus:ring-fuchsia-500"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fuchsia-500 focus:ring-fuchsia-500"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fuchsia-600 hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fuchsia-500"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-6">
                <div className="flex items-center">
                  <Mail className="h-6 w-6 text-fuchsia-600" />
                  <span className="ml-3 text-gray-500">support@vindr.com</span>
                </div>
                <div className="flex items-center">
                  <Phone className="h-6 w-6 text-fuchsia-600" />
                  <span className="ml-3 text-gray-500">+1 (555) 123-4567</span>
                </div>
                <div className="flex items-center">
                  <MapPin className="h-6 w-6 text-fuchsia-600" />
                  <span className="ml-3 text-gray-500">123 Runner's Street, City, State 12345</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              <a href="#" className="text-gray-400 hover:text-fuchsia-600">
                Terms
              </a>
              <a href="#" className="text-gray-400 hover:text-fuchsia-600">
                Privacy
              </a>
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              © 2024 Vindr. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MarketingPage;